<template>
  <div class="create-summary">
    <div class="form-content content position-relative">
      <h3 class="mb-3 bread-content">{{ stepNumber }}. {{ pageTitle }}</h3>
      <Settings
        v-if="Object.keys(settingsData).length && Object.keys(modelFields).length"
        :settingsData="settingsData"
        :modelFields="modelFields"
      />
      <Scheduling
        v-if="Object.keys(schedulingData).length"
        :schedulingData="schedulingData"
        :creationType="schedulingCreationType"
      />
      <EntrantsTags
        v-if="Object.keys(entrantsData).length"
        :entrantsData="entrantsData"
      />
      <Rewards
        v-if="rewardsData.length || showEmptyRewardsData"
        :rewardsData="rewardsData"
      />
      <ViewRules
        v-if="Object.keys(rulesData).length"
        :rulesData="rulesData"
        :context="rulesContext"
      />
      <Products
        v-if="Object.keys(productsData).length"
        :productsData="productsData"
      />
      <ViewTranslations
        v-if="Object.keys(settingsData).length"
        :entityData="settingsData"
        :createTranslationsData="translationsData"
        :translatableFields="translatableFields"
      />
    </div>
  </div>
</template>

<script>
import Settings from '@/shared/components/steps/components/Settings';
import ViewRules from '@/shared/components/supportModels/rules/ViewRules';
import Scheduling from './components/CompetitionScheduling';
import ViewTranslations from '@/shared/components/supportModels/translations/ViewTranslations';
import Rewards from './components/Rewards';
import Products from './components/Products';
import EntrantsTags from './components/EntrantsTags';

export default {
  name: "CompetitionSummaryStep",
  components: {
    EntrantsTags,
    Settings,
    ViewRules,
    Scheduling,
    ViewTranslations,
    Rewards,
    Products,
  },
  props: {
    settingsData: {
      type: Object,
      default() {
        return {}
      }
    },
    rulesData: {
      type: Object,
      default() {
        return {}
      }
    },
    dependantOnData: {
      type: Object,
      default() {
        return {}
      }
    },
    schedulingData: {
      type: Object,
      default() {
        return {}
      }
    },
    entrantsData: {
      type: Object,
      default() {
        return {}
      }
    },
    productsData: {
      type: Object,
      default() {
        return {}
      }
    },
    translationsData: {
      type: Object,
      default() {
        return {}
      }
    },
    rewardsData: {
      type: Array,
      default() {
        return []
      }
    },
    stepNumber: {
      type: Number,
      default: 8
    },
    pageTitle: {
      type: String,
      default: 'Summary'
    },
    schedulingCreationType: {
      type: String,
      default: 'advanced'
    },
    rulesContext: {
      type: String,
      default: 'competition'
    },
    model: {
      type: String,
      default: ''
    },
    translatableFields: {
      type: Array,
      default: () => [],
    },
    showEmptyRewardsData: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      texts: {},
      modelFields: {},
    }
  },
  async created() {
    let fieldsModule = await import("@/generated/ziqni/store/modules/" + this.model + "/fields" );
    this.modelFields = fieldsModule.default;
  },
}
</script>

<style lang="scss">
.create-summary {
  height: 100%;
  flex: 2;
  .form-content {
    height: calc(100% - 50px);
    background: var(--zq-sub-bg);
  }
}
</style>
